

/*FONT STYLE*/
$base-font: Arial;

/*BACKGROUND-COLOR*/
$body-background: #F3F3F3;

/*buttons-color*/
$main-button-background-color: #6BCDFD;
$main-button-font-color: #fff;
$main-button-border-color: #6BCDFD;

$second-button-background-color: #fff;
$second-button-font-color: #6BCDFD;
$second-button-border-color: #6BCDFD;

/*MAIN-HOVER-COLOR*/
$main-hover-color: #9898A5;


/*Title-color*/
$title-color: #3c597b;

/*Main-text-color*/
$main-text-color: #9898A5;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif:  Arial, sans-serif !default;
$font-family-serif:      "Times New Roman", Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:        $font-family-sans-serif !default;

$font-size-base:          16px !default;
$font-size-large:         ceil(($font-size-base * 1.125)) !default; // ~18px
$font-size-small:         ceil(($font-size-base * 0.813)) !default; // ~14px

$font-size-h1:            floor(($font-size-base * 2.75)) !default; // ~44px
$font-size-h2:            floor(($font-size-base * 1.75)) !default; // ~32px
$font-size-h3:            ceil(($font-size-base * 1.125)) !default; // ~26px
$font-size-h4:            ceil(($font-size-base * 1)) !default; // ~22px
$font-size-h5:            $font-size-base !default;
$font-size-h6:            ceil(($font-size-base * 0.6)) !default; // ~14px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.5 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family:    $font-family-sans-serif !default;
$headings-font-weight:    500 !default;
$headings-line-height:    1.1 !default;
$headings-color:          inherit !default;

$line-height-h1:          1.27 !default;
$line-height-h2:          1.125 !default;
$line-height-h3:          1.153 !default;
$line-height-h4:          1.09 !default;
$line-height-h5:          1.27 !default;
$line-height-h6:          1.27 !default;


.guidance-popup-hover.dx-popup-wrapper > .dx-overlay-content{
    background-color: #6DCBFB !important;   
     .dx-popover-arrow:after, .dx-popover-arrow:after
        {
          background-color:#6DCBFB !important;
        }    
} 